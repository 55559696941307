import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import Banner from '../../components/Banner';

import settings from '../../settings';

import Reveal from '../../components/Reveal';

import CareersForm from './Form';

export default function() {

	return (
		<Layout>
			<Helmet>
				<title>Careers • Blackstone VT</title>
				<link rel="canonical" href={`${settings.host.url}/careers`} />
				<meta
					name="description"
					content="Work for Blackstone Vermont as a stone mason, mower, or landscaping generalist. Consider a career with us starting with any level of experience."
				/>
			</Helmet>
		  <Banner isH1={true}>Join the team</Banner>
		  <div className="container">
			<div className="row">
			  <div className="col-lg-6 offset-lg-3"style={{paddingTop:'20px'}}>
			  	<Reveal>
			  		<p>Whether you're an established landscaper or interested in learning, let us know! We may have a job for you.</p>
				  	<p>Complete the form below and we'll be in touch.</p>
			  	</Reveal>
				<CareersForm/>
			  </div>
			</div>
		  </div>
		<Banner shadowBottom={false} backgroundColor="Grey" styles={{
				height: '30px',
				lineHeight: '30px',
				fontSize: '22px',
			}}>Open Jobs</Banner>
		
		<JobPosting
			jobTitle="Grass Mower"
			jobLocation="Chester, VT 05143"
			payRate="$17/hr +"
			isUrgent={true}
		>
			<h6 className="text-muted">Must haves:</h6>
			<ul>
				<li>Safe work habits</li>
				<li>Attention to detail</li>
				<li>Care about quality</li>
				<li>Accountability</li>
			</ul>
			<h6 className="text-muted">Notes</h6>
			<ul>
				<li>Pay depends on experience (DoE)</li>
				<li>On the job training <i>(No experience needed)</i></li>
			</ul>
		</JobPosting>

		</Layout>
	);
}

function JobPosting({children,jobTitle,jobLocation,payRate,isUrgent}){

	return (
		<Reveal className="mx-auto">
		<div className="card border-success shadow-lg m-3 font-weight-lighter" style={{
			maxWidth: '18rem',
		}}>
			<div className="card-header d-flex align-items-center">
				<strong className="mr-auto">{jobTitle}</strong>
				<span className="badge badge-pill badge-primary mr-2 font-weight-lighter">{payRate}</span>
			</div>
			<div className="card-body text-dark">
				<h6 className="card-title">{isUrgent?"🕓 Urgently Hiring":''}</h6>
				<div className="card-text">{children}</div>
			</div>
			<div className="card-footer"><span role="img" aria-label="Map Pin Symbol">📍</span> {jobLocation}</div>
		</div>
		</Reveal>
	);
}
